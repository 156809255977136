import { FC, Fragment } from 'react'
import { styled } from '@liftfoils/styles'
import {
  Media,
  ProductVariantPrice,
  MediaPlaceholder,
  Button,
} from '@liftfoils/components'
import { OptionValueProps } from './ProductOption'
import { useAccount } from '@liftfoils/services/shopify-service'

const Root = styled('div', {
  display: 'grid',
  position: 'relative',
  overflow: 'hidden',
  gap: '$4',
})
const LabelWrap = styled('label', {
  gridArea: '1/1',
  cursor: 'pointer',
  border: '1px solid',
  borderColor: '$gray300_03',
  borderRadius: '$r1',
  lift_font: 'body06',
  textAlign: 'center',
  minHeight: '$11',
  display: 'grid',
  gridTemplateRows: '1fr auto',
  transition: 'border-color 100ms',
  '&:hover': {
    borderColor: '$gray300',
  },
})
const SelectedOverlay = styled('div', {
  gridArea: '1/1',
  zIndex: 2,
  pointerEvents: 'none',
  opacity: 0,
  border: '2px solid $teal500',
  borderRadius: '$r1',
  variants: {
    selected: {
      true: {
        opacity: 1,
      },
    },
    disabled: {
      true: {
        borderColor: '$gray200',
      },
    },
  },
})
const Footer = styled('div', {
  lift_font: 'body04',
  borderTop: '1px solid',
  borderColor: '$gray200',
  p: '$6',
})
const Features = styled('div', {
  color: '$gray400',
})
const Body = styled('div', {
  p: '$6',
  display: 'grid',
  gridGap: '$5',
  overflow: 'hidden',
  variants: {
    hasImage: {
      true: { gridTemplateRows: 'auto 1fr' },
      false: {
        alignContent: 'center',
      },
    },
  },
})
const InputRadio = styled('input', {
  appearance: 'none',
  border: 0,
  position: 'absolute',
})

export const ProductOptionValue: FC<OptionValueProps> = ({
  features,
  id,
  image,
  onChange,
  optionId,
  selected,
  title,
  price,
  disabled,
  showNotifyMe,
}) => {
  const mixedId = `${optionId}_${id}`
  const { isB2B } = useAccount()

  return (
    <Root>
      <InputRadio
        type={'radio'}
        id={mixedId}
        name={optionId}
        value={id}
        onChange={() => (onChange && id ? onChange(id, optionId) : null)}
        checked={selected}
        tabIndex={disabled ? -1 : 0}
        disabled={disabled}
      />
      <LabelWrap htmlFor={mixedId}>
        <Body hasImage={!!image}>
          {image && (
            <MediaPlaceholder ratio={'square'}>
              <Media
                {...image}
                sizes={'200px'}
                layout={'fill'}
                objectFit={'contain'}
                alt={image.alt ?? ''}
              />
            </MediaPlaceholder>
          )}
          <div>
            {title}
            {features && (
              <Features>
                {features.map((a, i) => (
                  <Fragment key={i}>
                    {a}
                    <br />
                  </Fragment>
                ))}
              </Features>
            )}
          </div>
        </Body>

        {price && parseFloat(price?.amount) !== 0 && (
          <Footer>
            {isB2B && (
              <>
                MSRP
                <br />
              </>
            )}
            + <ProductVariantPrice price={price} />
          </Footer>
        )}
      </LabelWrap>
      <SelectedOverlay selected={selected} disabled={disabled} />
      {showNotifyMe && (
        <Button
          appearance={'solidGray500'}
          size={'small'}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window._klOnsite = window._klOnsite || []
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window._klOnsite.push(['openForm', 'SXd8A7'])
          }}
        >
          Notify me
        </Button>
      )}
    </Root>
  )
}
