import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { ShopifyResolvedProductBasic } from '@liftfoils/shopify-sdk'
import { Button, Media, PriceRange } from '@liftfoils/components'
import { ProductOption } from './ProductOption'
import { useTranslation } from 'react-i18next'
import { ROUTE_BASE_PRODUCTS } from '@liftfoils/configs'
import Link from 'next/link'

const Root = styled('div', {
  display: 'grid',
  gridGap: '$8',
  p: '$6',
  backgroundColor: '$gray100',
  borderRadius: '$r1',
})
const Main = styled('div', {
  display: 'grid',
  gridGap: '$6',
  gridTemplateColumns: '102px 1fr auto',
})
const Buttons = styled('div', {})
const Title = styled('div', {
  lift_font: 'body03',
  color: '$gray500',
  pt: '$2',
})
const Description = styled('div', {
  lift_font: 'body03',
  color: '$gray300',
})

export type AddonProduct = ShopifyResolvedProductBasic & {
  contextualDescription?: string
}

export const Addon: FC<{
  product: AddonProduct
  onAddonChange: (
    productId: string,
    optionName: string,
    optionValue: string,
  ) => void
  onAddonAdd: (id: string) => void
  onAddonRemove: (id: string) => void
  selectedAddOns: Record<string, Record<string, string>>
}> = ({
  selectedAddOns,
  onAddonAdd,
  onAddonRemove,
  onAddonChange,
  product,
}) => {
  const isAdded = typeof selectedAddOns[product.id] === 'object'
  const { t } = useTranslation('product')

  return (
    <>
      <Root>
        <Main>
          <div>
            {product.featuredImage && (
              <Media mediaPayload={product.featuredImage} />
            )}
          </div>
          <div>
            <Link href={`${ROUTE_BASE_PRODUCTS}/${product.handle}`}>
              <Title>{product.title}</Title>
            </Link>
            <Description>
              <PriceRange range={product.priceRange} /> <br />
              {product.contextualDescription}
            </Description>
          </div>
          <Buttons>
            {isAdded ? (
              <Button
                onClick={() => onAddonRemove(product.id)}
                appearance={'solidGray500'}
                size={'small'}
              >
                {t('addonRemove')}
              </Button>
            ) : (
              <Button
                onClick={() => onAddonAdd(product.id)}
                appearance={'solidTeal500'}
                size={'small'}
              >
                {t('addonAdd')}
              </Button>
            )}
          </Buttons>
        </Main>

        {isAdded ? (
          <>
            {product.options.map((option) => (
              <ProductOption
                id={option.name}
                key={option.name}
                title={option.name}
                values={option.values.map((value) => ({
                  optionId: option.name,
                  title: value,
                  id: value,
                  selected: selectedAddOns[product.id]?.[option.name] === value,
                }))}
                onChange={(val, name) => {
                  onAddonChange(product.id, name, val)
                }}
                isActive
              />
            ))}
          </>
        ) : null}
      </Root>
    </>
  )
}
